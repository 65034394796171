<div class="center">
  <h2 class="chore-name">
    <mat-icon>{{ chore.icon }}</mat-icon> {{ chore.name }}
  </h2>
  <p>{{ chore.description }}</p>

  <br />
  <mat-divider></mat-divider>
  <br /><br />

  <h2 class="confirm-completion">Confirm completion</h2>

  <form
    name="confirmForm"
    #confirmForm="ngForm"
    (submit)="confirm(confirmForm)"
  >
    <div class="stars">
      <button
        type="button"
        mat-icon-button
        (click)="stars = 1"
        [color]="stars > 0 ? 'accent' : ''"
      >
        <mat-icon>star</mat-icon>
      </button>
      <button
        type="button"
        mat-icon-button
        (click)="stars = 2"
        [color]="stars > 1 ? 'accent' : ''"
      >
        <mat-icon>star</mat-icon>
      </button>
      <button
        type="button"
        mat-icon-button
        (click)="stars = 3"
        [color]="stars > 2 ? 'accent' : ''"
      >
        <mat-icon>star</mat-icon>
      </button>
      <button
        type="button"
        mat-icon-button
        (click)="stars = 4"
        [color]="stars > 3 ? 'accent' : ''"
      >
        <mat-icon>star</mat-icon>
      </button>
      <button
        type="button"
        mat-icon-button
        (click)="stars = 5"
        [color]="stars > 4 ? 'accent' : ''"
      >
        <mat-icon>star</mat-icon>
      </button>
    </div>

    <span class="points" *ngIf="stars"
      >{{ getPoints() }}/{{ chore.points }} points</span
    >
    <button mat-flat-button color="primary">CONFIRM</button>
  </form>

  <div class="center buttons">
    <button mat-button color="warn" (click)="deleteChore()">DELETE</button>
    <button mat-button (click)="editChore()">EDIT</button>
  </div>
</div>
