import { Injectable } from '@angular/core';
import { Grownup, Kid } from '../classes/person.class';
import { FirebaseService } from './firebase.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class KidService {

  constructor(
    private state: StateService,
    private firebase: FirebaseService
  ) { }

  createKid(name: string) {
    this.state.kids.push(new Kid(name))
    this.saveKids()
  }

  findKid(name: string) {
    return this.state.kids.find(kid => kid.name === name)
  }

  deleteKid(kid: Kid) {
    let index = this.state.kids.indexOf(kid)
    this.state.kids.splice(index, 1)
    this.saveKids()
  }

  saveKids() {
    this.firebase.save(this.state.reference, { kids: this.state.kids, chores: this.state.chores })
  }
}
