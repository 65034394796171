import { Injectable } from '@angular/core';
import { Chore } from '../classes/chore.class';
import { FirebaseService } from './firebase.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class ChoreService {

  newChores = [
    new Chore('Set the table', 'Set the table before dinner', 'restaurant', 10),
    new Chore('Put away dishes', 'Help to put away the dishes', 'radio_button_checked', 15),
    new Chore('Sort laundry', 'Help to sort all the laundry', 'local_laundry_service', 25),
    new Chore('Put away laundry', 'Put away all of your laundry', 'checkroom', 15),
    new Chore('Help clean the house', 'Help with general cleaning', 'weekend', 15),
  ].sort((a, b) => a.points < b.points ? 1 : -1)

  constructor(
    private state: StateService,
    private firebase: FirebaseService
  ) { }

  async addChore(name: string, description: string, icon: string, points: number, choreToUpdate: Chore = undefined) {
    this.state.chores = (<any>await this.firebase.get(this.state.reference)).chores
    let chore = new Chore(name, description, icon, points)
    if (!choreToUpdate) this.state.chores.push(chore)
    else {
      let _chore = this.state.chores.find(c => c.name === choreToUpdate.name)
      let index = this.state.chores.indexOf(_chore)
      this.state.chores.splice(index, 0, chore)
    }
    await this.saveChores()
  }

  async saveChores() {
    await this.firebase.save(this.state.reference, { kids: this.state.kids, chores: this.state.chores })
  }

  async deleteChore(chore: Chore) {
    this.state.chores = (<any>await this.firebase.get(this.state.reference)).chores
    let _chore = this.state.chores.find(c => c.name === chore.name)
    let index = this.state.chores.indexOf(_chore)
    this.state.chores.splice(index, 1)
    await this.firebase.save(this.state.reference, { kids: this.state.kids, chores: this.state.chores })
  }
}
