<div class="center">
  <h1>Point collection</h1>

  <div class="points">
    100 <mat-slider min="100" max="{{maxPoints}}" step="100" [(ngModel)]="points"></mat-slider> {{maxPoints}}
  </div>
  <div class="to-collect">
    <h1 class="value">({{points}} points)</h1>
    <span class="in-points">{{points / 100 | currency : 'CHF '}}</span>
  </div>
  <br>

  <mat-divider></mat-divider>


  <mat-nav-list *ngIf="!grownup">
    <mat-list-item *ngFor="let _grownup of kid.grownups" (click)="grownup = _grownup">
      <mat-icon mat-list-icon>face</mat-icon>
      <div mat-line><b>{{_grownup.name}}</b></div>
    </mat-list-item>
  </mat-nav-list>

  <div *ngIf="grownup">
    <form name="confirmForm" #confirmForm="ngForm" (submit)="confirm(confirmForm)">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Password</mat-label>
        <input matInput name="grownup-password" type="password" [(ngModel)]="password" required>
      </mat-form-field>
      <button mat-flat-button color="primary">CONFIRM</button>
    </form>
  </div>

</div>