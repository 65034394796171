import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { randomString } from 'src/app/lib/random-string';
import { ChoreService } from 'src/app/services/chore.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-ref-page',
  templateUrl: './ref-page.component.html',
  styleUrls: ['./ref-page.component.scss']
})
export class RefPageComponent {

  reference: string

  constructor(
    private state: StateService,
    private firebase: FirebaseService,
    private router: Router,
    private snackBar: MatSnackBar,
    private choreService: ChoreService
  ) { }

  async auth(form: NgForm) {
    if (!form.valid) return
    let resp: any = await this.firebase.get(this.reference)
    if (resp) {
      this.state.kids = resp.kids
      this.state.chores = resp.chores
      this.state.reference = this.reference
      this.state.authenticated = true
      localStorage.setItem('reference', this.reference)
      this.router.navigate(['app'])
    } else {
      this.snackBar.open('Reference not found', null, { duration: 2000 })
    }
  }

  async createNew() {
    let reference = randomString(4) + '-' + randomString(4) + '-' + randomString(4)
    this.state.authenticated = true
    this.state.reference = reference
    await this.firebase.save(reference, { kids: [], chores: this.choreService.newChores })
    localStorage.setItem('reference', reference)
    this.state.chores = this.choreService.newChores.slice()
    this.router.navigate(['app'])
  }

}
