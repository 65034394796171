import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor(
    private state: StateService,
    private router: Router
  ) { }

  logout() {
    this.state.reference = undefined
    this.state.authenticated = false
    localStorage.removeItem('reference')
    this.router.navigate([''])
  }

}
