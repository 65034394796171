import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedPageComponent } from './pages/authenticated-page/authenticated-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { KidPageComponent } from './pages/kid-page/kid-page.component';
import { ManageParentsComponent } from './pages/manage-parents/manage-parents.component';
import { RefPageComponent } from './pages/ref-page/ref-page.component';
import { AuthResolve } from './resolves/auth.resolve';
import { GrownupProtectedResolve } from './resolves/grownup-protected.resolve';
import { KidResolve } from './resolves/kid.resolve';
import { NotAuthResolve } from './resolves/not-auth.resolve';

const routes: Routes = [
  {
    path: '',
    component: RefPageComponent,
    resolve: { data: NotAuthResolve }
  },
  {
    path: 'app',
    component: AuthenticatedPageComponent,
    resolve: { data: AuthResolve },
    children: [
      {
        path: '',
        component: HomePageComponent
      },
      {
        path: 'kid/:name',
        component: KidPageComponent,
        resolve: { kid: KidResolve }
      },
      {
        path: 'manage-grownups/:name',
        component: ManageParentsComponent,
        resolve: { kid: KidResolve, allowed: GrownupProtectedResolve }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
