<app-header>
  <div header>Kid | {{ kid.name }}</div>
</app-header>

<div class="page">
  <mat-tab-group (selectedTabChange)="selectedTabChange($event)">
    <mat-tab label="Chores">
      <div class="points">
        <h1>{{ kid.points }}</h1>
        <span>Points</span>
        <button
          mat-flat-button
          color="primary"
          (click)="collect()"
          [disabled]="kid.points < 100"
        >
          COLLECT
        </button>
      </div>

      <div cdkDropList (cdkDropListDropped)="drop($event)">
        <div
          *ngFor="let chore of state.chores"
          (click)="doChore(chore)"
          cdkDrag
          class="chore-list-item"
        >
          <mat-icon mat-list-icon>{{ chore.icon }}</mat-icon>
          <div class="meta">
            <div>
              <b>{{ chore.name }}</b>
            </div>
            <div>{{ chore.description }}</div>
          </div>
          <div>{{ chore.points }}</div>
        </div>
      </div>

      <br /><br />

      <div class="center">
        <button mat-flat-button color="accent" (click)="showAddTaskDialog()">
          ADD CHORE
        </button>
      </div>
    </mat-tab>

    <mat-tab label="History">
      <br />
      <mat-radio-group
        [(ngModel)]="chartDataType"
        name="chartDataTypes"
        (change)="initChart()"
      >
        <mat-radio-button *ngFor="let type of chartDataTypes" [value]="type">{{
          type
        }}</mat-radio-button>
      </mat-radio-group>

      <br /><br />

      <canvas
        baseChart
        width="800"
        height="400"
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [colors]="lineChartColors"
        [legend]="false"
        [chartType]="'line'"
      >
      </canvas>

      <div>
        <div
          *ngFor="
            let chore of kid.doneChores | orderBy : 'completionTime' : true
          "
          class="chore-list-item"
        >
          <div class="meta no-margin">
            <div>
              <b>{{ chore.name }}</b>
            </div>
            <div class="chore-points-and-stars">
              <div class="chore-points">
                {{ (chore.points / 5) * chore.stars }} points
              </div>
              <div class="chore-stars">
                <mat-icon *ngFor="let star of getStars(chore.stars)"
                  >star</mat-icon
                >
                <mat-icon *ngFor="let star of getStars(5 - chore.stars)"
                  >star_border</mat-icon
                >
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div>{{ chore.completionTime | date : "dd/MM/yy, HH:mm" }}</div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
