import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Chore } from 'src/app/classes/chore.class';
import { ChoreService } from 'src/app/services/chore.service';
import { IconsService } from 'src/app/services/icons.service';

@Component({
  selector: 'app-edit-task',
  templateUrl: './edit-task.component.html',
  styleUrls: ['./edit-task.component.scss'],
})
export class EditTaskComponent {
  name: string;
  description: string;
  points: number;
  icon: string;
  chore: Chore;

  constructor(
    public dialogRef: MatDialogRef<EditTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public iconService: IconsService,
    private snackBar: MatSnackBar,
    private choreService: ChoreService
  ) {
    this.chore = data.chore;
    this.name = data.chore.name;
    this.description = data.chore.description;
    this.points = data.chore.points;
    this.icon = data.chore.icon;
  }

  async saveChore(form: NgForm) {
    if (!form.valid) return;

    this.chore.name = this.name;
    this.chore.description = this.description;
    this.chore.points = this.points;
    this.chore.icon = this.icon;

    if (!this.icon) return this.snackBar.open('Select an icon');
    await this.choreService.saveChores();
    this.dialogRef.close();
  }
}
