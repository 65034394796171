import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { GrownupPasswordComponent } from '../dialogs/grownup-password/grownup-password.component';
import { StateService } from '../services/state.service';

@Injectable({
  providedIn: 'root'
})
export class GrownupProtectedResolve implements Resolve<any> {
  constructor(private router: Router, private state: StateService, private dialog: MatDialog) { }

  async resolve(route: ActivatedRouteSnapshot) {
    let kid = this.state.kids.find(kid => kid.name === route.params.name)
    if (!kid) return
    if (!kid.grownups.length) return
    const dialogRef = this.dialog.open(GrownupPasswordComponent, { data: { kid } })
    let resp = await dialogRef.afterClosed().toPromise()
    if (!resp) this.router.navigate(['app'])
  }
}