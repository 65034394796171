import { Component } from '@angular/core';
import { ChoreService } from './services/chore.service';
import { GrownupService } from './services/grownup.service';
import { KidService } from './services/kid.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private kidService: KidService,
    private grownupService: GrownupService,
    private choreService: ChoreService
  ) { }
}
