import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Chore, DoneChore } from 'src/app/classes/chore.class';
import { Kid } from 'src/app/classes/person.class';
import { AddTaskComponent } from 'src/app/dialogs/add-task/add-task.component';
import { ChoreConfirmComponent } from 'src/app/dialogs/chore-confirm/chore-confirm.component';
import { CollectComponent } from 'src/app/dialogs/collect/collect.component';
import { GrownupPasswordComponent } from 'src/app/dialogs/grownup-password/grownup-password.component';
import { addDays } from 'src/app/lib/add-days';
import { diffInDays } from 'src/app/lib/diff-in-days';
import { ChoreService } from 'src/app/services/chore.service';
import { KidService } from 'src/app/services/kid.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-kid-page',
  templateUrl: './kid-page.component.html',
  styleUrls: ['./kid-page.component.scss'],
})
export class KidPageComponent {
  kid: Kid;
  chartDataTypes = [
    'Points',
    'Points accumulated',
    'Chores',
    'Chores accumulated',
  ];
  chartDataType = this.chartDataTypes[0];
  lineChartLabels: Label[] = [];
  public lineChartData: ChartDataSets[] = [{ data: [], label: 'Points' }];
  public lineChartOptions: ChartOptions = { responsive: true, scales: {} };
  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(63,81,181,0.2)',
      borderColor: '#3f51b5',
      pointBackgroundColor: '#3f51b5',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(63,81,181,0.8)',
    },
  ];
  daysToShow = 14;

  constructor(
    private route: ActivatedRoute,
    public state: StateService,
    private kidService: KidService,
    private choreService: ChoreService,
    private dialog: MatDialog
  ) {
    this.kid = this.route.snapshot.data.kid;

    for (let i = 0; i < this.daysToShow; i++) {
      this.lineChartLabels.unshift(
        addDays(new Date(), -i).getDate().toString()
      );
    }
    this.initChart();
  }

  initChart() {
    this.lineChartData[0].data = [];
    for (let i = 0; i < this.daysToShow; i++)
      this.lineChartData[0].data.push(0);

    let now = new Date();
    this.kid.doneChores.forEach((chore) => {
      let daysAgo = diffInDays(new Date(chore.completionTime), now);
      let index = this.daysToShow - 1 - daysAgo;
      if (
        this.chartDataType === 'Points' ||
        this.chartDataType === 'Points accumulated'
      ) {
        this.lineChartData[0].data[index] =
          <any>this.lineChartData[0].data[index] +
          (chore.points / 5) * chore.stars;
      }
      if (
        this.chartDataType === 'Chores' ||
        this.chartDataType === 'Chores accumulated'
      ) {
        this.lineChartData[0].data[index] =
          <any>this.lineChartData[0].data[index] + 1;
      }
    });

    if (this.chartDataType.indexOf('accumulated') > -1) {
      let total = 0;
      this.lineChartData[0].data.forEach((num: any, index: number) => {
        total += num;
        this.lineChartData[0].data[index] = total;
      });
    }
  }

  selectedTabChange(event: any) {
    if (event.index === 1) this.initChart();
  }

  async doChore(chore: Chore) {
    const dialogRef = this.dialog.open(GrownupPasswordComponent, {
      data: { kid: this.kid },
    });
    let allowed = await dialogRef.afterClosed().toPromise();
    if (!allowed) return;
    const dialogRef2 = this.dialog.open(ChoreConfirmComponent, {
      width: '90vw',
      data: { chore, kid: this.kid },
    });
    dialogRef2.afterClosed().subscribe(async (resp) => {
      if (!resp) return;
      let points = Math.round((chore.points / 5) * resp.stars);
      await this.state.update();
      this.kid = this.kidService.findKid(this.kid.name);
      this.kid.points += points;
      this.kid.doneChores.push(
        new DoneChore(chore.name, chore.points, resp.stars)
      );
      this.kidService.saveKids();
    });
  }

  collect() {
    const dialogRef = this.dialog.open(CollectComponent, {
      width: '90vw',
      data: { kid: this.kid },
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (!resp) return;
      this.kid.points -= resp;
      this.kidService.saveKids();
    });
  }

  async showAddTaskDialog() {
    const dialogRef = this.dialog.open(GrownupPasswordComponent, {
      data: { kid: this.kid },
    });
    let allowed = await dialogRef.afterClosed().toPromise();
    if (!allowed) return;
    const dialogRef2 = this.dialog.open(AddTaskComponent, {
      width: '90vw',
      maxHeight: '90vh',
    });
    let resp = await dialogRef2.afterClosed().toPromise();
    if (!resp) return;
    this.choreService.addChore(
      resp.name,
      resp.description,
      resp.icon,
      resp.points
    );
  }

  drop(event: CdkDragDrop<Chore[]>) {
    moveItemInArray(this.state.chores, event.previousIndex, event.currentIndex);
    this.choreService.saveChores();
  }

  getStars(num: number): any[] {
    return new Array(num);
  }
}
