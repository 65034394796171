import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(array: any[], fieldName: string, negative = false): unknown {
    let out = array.slice()
    out.sort((a, b) => {
      if (negative) return a[fieldName] < b[fieldName] ? 1 : -1
      else return a[fieldName] > b[fieldName] ? 1 : -1
    })
    return out
  }

}
