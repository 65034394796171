import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Grownup, Kid } from 'src/app/classes/person.class';

@Component({
  selector: 'app-collect',
  templateUrl: './collect.component.html',
  styleUrls: ['./collect.component.scss']
})
export class CollectComponent {

  kid: Kid
  maxPoints: number
  points: number
  grownup: Grownup
  password: string

  constructor(
    public dialogRef: MatDialogRef<CollectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) {
    this.kid = this.data.kid
    if (this.kid.grownups.length === 1) this.grownup = this.kid.grownups[0]
    this.maxPoints = Math.floor(this.kid.points / 100) * 100
    this.points = this.maxPoints
  }

  confirm(form: NgForm) {
    if (!form.valid) return
    if (this.password !== this.grownup.password) {
      return this.snackBar.open('Wrong password', null, { duration: 2500 })
    }
    this.dialogRef.close(this.points)
  }
}
