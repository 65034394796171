import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { StateService } from '../services/state.service';

@Injectable({
  providedIn: 'root'
})
export class KidResolve implements Resolve<any> {
  constructor(private router: Router, private state: StateService) { }

  async resolve(route: ActivatedRouteSnapshot) {
    let kid = this.state.kids.find(kid => kid.name === route.params.name)
    if (kid) return kid
    this.router.navigate(['app'])
  }
}