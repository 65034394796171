<mat-toolbar color="primary">
  <span class="logo" routerLink="/"><ng-content select="[header]"></ng-content></span>
  <span class="spacer"></span>
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="logout()">
      <mat-icon>logout</mat-icon>Logout
    </button>
  </mat-menu>
</mat-toolbar>