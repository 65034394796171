import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IconsService } from 'src/app/services/icons.service';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss']
})
export class AddTaskComponent {

  name: string
  description: string
  points: number
  icon: string

  constructor(
    public dialogRef: MatDialogRef<AddTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public iconService: IconsService,
    private snackBar: MatSnackBar
  ) { }

  addChore(form: NgForm) {
    if (!form.valid) return
    if (!this.icon) return this.snackBar.open('Select an icon')
    this.dialogRef.close({ name: this.name, points: this.points, description: this.description, icon: this.icon })
  }

}
