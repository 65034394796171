import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { FirebaseService } from '../services/firebase.service';
import { StateService } from '../services/state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthResolve implements Resolve<any> {
  constructor(private router: Router, private state: StateService, private firebase: FirebaseService) { }

  async resolve(route: ActivatedRouteSnapshot) {
    let reference: string
    if (route.queryParams.ref) reference = route.queryParams.ref

    if (!reference) reference = localStorage.getItem('reference')
    if (reference) {
      let data: any = await this.firebase.get(reference)
      if (data) {
        this.state.kids = data.kids
        this.state.chores = data.chores
        this.state.authenticated = true
        this.state.reference = reference
        localStorage.setItem('reference', reference)
        return
      } else {
        localStorage.removeItem('reference')
      }
    }

    if (!this.state.authenticated) this.router.navigate([''])
  }
}