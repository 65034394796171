import { DoneChore } from "./chore.class"

export class Kid {
  name: string
  points: number = 0
  doneChores: DoneChore[] = []
  grownups: Grownup[] = []

  constructor(name: string) {
    this.name = name
  }
}

export class Grownup {
  name: string
  password: string

  constructor(name: string, password: string) {
    this.name = name
    this.password = password
  }
}