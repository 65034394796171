import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { AddTaskComponent } from './dialogs/add-task/add-task.component';
import { ChoreConfirmComponent } from './dialogs/chore-confirm/chore-confirm.component';
import { CollectComponent } from './dialogs/collect/collect.component';
import { EditTaskComponent } from './dialogs/edit-task/edit-task.component';
import { GrownupPasswordComponent } from './dialogs/grownup-password/grownup-password.component';
import { RefComponent } from './dialogs/ref/ref.component';
import { AuthenticatedPageComponent } from './pages/authenticated-page/authenticated-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { KidPageComponent } from './pages/kid-page/kid-page.component';
import { ManageParentsComponent } from './pages/manage-parents/manage-parents.component';
import { RefPageComponent } from './pages/ref-page/ref-page.component';
import { OrderByPipe } from './pipes/order-by.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    KidPageComponent,
    ManageParentsComponent,
    HeaderComponent,
    ChoreConfirmComponent,
    CollectComponent,
    GrownupPasswordComponent,
    RefComponent,
    RefPageComponent,
    AuthenticatedPageComponent,
    AddTaskComponent,
    EditTaskComponent,
    OrderByPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDividerModule,
    MatSliderModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    HttpClientModule,
    DragDropModule,
    MatTabsModule,
    ChartsModule,
    MatRadioModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
