<app-header>
  <div header>Chore app</div>
</app-header>
<div class="page">
  <h1>Login</h1>

  <form name="authForm" #authForm="ngForm" (submit)="auth(authForm)">
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Reference</mat-label>
      <input matInput name="grownup-name" [(ngModel)]="reference" required>
    </mat-form-field>

    <div class="center">
      <button mat-flat-button color="primary">SUBMIT</button>
    </div>
  </form>

  <br><br>

  <div class="center">
    <button mat-flat-button (click)="createNew()">CREATE NEW</button>
  </div>

</div>