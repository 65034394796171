<app-header>
  <div header>Manage grownups | {{kid.name}}</div>
</app-header>

<div class="page">
  <h1>Grownups</h1>
  <mat-list>
    <mat-list-item *ngFor="let grownup of kid.grownups">
      <mat-icon mat-list-icon>face</mat-icon>
      <div mat-line><b>{{grownup.name}}</b></div>
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="menuClick($event)"><mat-icon>more_vert</mat-icon></button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="deleteGrownup(grownup)">Delete grownup</button>
      </mat-menu>
    </mat-list-item>
  </mat-list>
  <div *ngIf="!kid.grownups.length" class="no-grownups-yet">
    No grownups yet
  </div>
  <br><br>
  <h1>Add a grownup</h1>
  <form name="greateGrownupForm" #greateGrownupForm="ngForm" (submit)="createGrownup(greateGrownupForm)">
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Grownups name</mat-label>
      <input matInput name="grownup-name" [(ngModel)]="grownupName" required>
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Grownups password</mat-label>
      <input matInput name="grownup-password" type="password" [(ngModel)]="grownupPassword" required>
    </mat-form-field>
    <div class="center">
      <button mat-flat-button color="primary">ADD GROWNUP</button>
    </div>
  </form>
</div>