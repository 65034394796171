import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Chore } from 'src/app/classes/chore.class';
import { Kid } from 'src/app/classes/person.class';
import { ChoreService } from 'src/app/services/chore.service';
import { EditTaskComponent } from '../edit-task/edit-task.component';

@Component({
  selector: 'app-chore-confirm',
  templateUrl: './chore-confirm.component.html',
  styleUrls: ['./chore-confirm.component.scss'],
})
export class ChoreConfirmComponent {
  chore: Chore;
  kid: Kid;
  stars: number;

  constructor(
    public dialogRef: MatDialogRef<ChoreConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private choreService: ChoreService,
    private dialog: MatDialog
  ) {
    this.chore = this.data.chore;
    this.kid = this.data.kid;
  }

  confirm(form: NgForm) {
    if (!form.valid) return;
    if (!this.stars)
      return this.snackBar.open('Select stars', null, { duration: 2500 });
    this.dialogRef.close({ stars: this.stars });
  }

  getPoints() {
    return (this.chore.points / 5) * this.stars;
  }

  deleteChore() {
    let dialogRef = this.snackBar.open('Chore deleted', 'undo');
    this.choreService.deleteChore(this.chore);
    this.dialogRef.close();
    dialogRef.onAction().subscribe(() => {
      this.choreService.addChore(
        this.chore.name,
        this.chore.description,
        this.chore.icon,
        this.chore.points,
        this.chore
      );
    });
  }

  editChore() {
    this.dialog.open(EditTaskComponent, {
      width: '90vw',
      maxHeight: '90vh',
      data: {
        chore: this.chore,
      },
    });
  }
}
