<div class="center">
  <h2 class="chore-name">Edit {{ name }}</h2>

  <form
    name="addChoreForm"
    #addChoreForm="ngForm"
    (submit)="saveChore(addChoreForm)"
  >
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Name</mat-label>
      <input matInput name="name" type="text" [(ngModel)]="name" required />
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        name="description"
        [(ngModel)]="description"
        required
      ></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Points</mat-label>
      <input
        matInput
        name="points"
        min="1"
        type="number"
        [(ngModel)]="points"
        required
      />
    </mat-form-field>

    <h3>Pick an icon</h3>
    <div class="icons">
      <div
        class="icon"
        *ngFor="let _icon of iconService.icons"
        [class.selected]="icon === _icon"
        (click)="icon = _icon"
      >
        <mat-icon>{{ _icon }}</mat-icon>
      </div>
    </div>

    <br /><br />

    <div class="center">
      <button mat-flat-button color="primary">CONFIRM</button>
    </div>
  </form>
</div>
