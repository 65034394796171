export class Chore {
  name: string
  description: string
  points: number
  icon: string

  constructor(name: string, description: string, icon: string, points: number) {
    this.name = name
    this.description = description
    this.points = points
    this.icon = icon
  }
}

export class DoneChore {
  name: string
  points: number
  stars: number
  completionTime: number = Date.now()

  constructor(name: string, points: number, stars: number) {
    this.name = name
    this.points = points
    this.stars = stars
  }
}