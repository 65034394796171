import { Injectable } from '@angular/core';
import { Chore } from '../classes/chore.class';
import { Grownup, Kid } from '../classes/person.class';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  kids: Kid[] = []
  chores: Chore[] = []
  authenticated = false
  reference: string

  constructor(
    private firebase: FirebaseService
  ) { }

  async update() {
    let data: any = await this.firebase.get(this.reference)
    if (data) {
      this.kids = data.kids
      this.chores = data.chores
    }
  }
}
