<app-header><div header>Chore app</div></app-header>
<div class="page">
  <h1>Children</h1>
  <mat-nav-list>
    <mat-list-item *ngFor="let kid of state.kids" routerLink="/app/kid/{{kid.name}}">
      <mat-icon mat-list-icon>child_care</mat-icon>
      <div mat-line><b>{{kid.name}}</b></div>
      <div mat-line>{{kid.points}} points</div>
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="menuClick($event)">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/app/manage-grownups/{{kid.name}}">
          <mat-icon>supervisor_account</mat-icon>Manage grownups
        </button>
        <button mat-menu-item (click)="deleteKid(kid)">
          <mat-icon>delete</mat-icon>Delete child
        </button>
      </mat-menu>
    </mat-list-item>
  </mat-nav-list>
  <br><br>
  <h1>Add a new child</h1>
  <form name="createKidForm" #createKidForm="ngForm" (submit)="createKid(createKidForm)">
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Childs name</mat-label>
      <input matInput name="kid-name" [(ngModel)]="kidName" required>
    </mat-form-field>
    <div class="center">
      <button mat-flat-button color="primary">ADD CHILD</button>
    </div>
  </form>
</div>

<div class="center">
  <br>
  <br>
  <mat-divider></mat-divider>
  <br>
  <h3 class="bottomless">Give someone else access to this account</h3>
  <img [src]="qrCodeImage" class="qr-code" alt="">
  <div class="reference">{{state.reference}}</div>
</div>