// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAw15cVlkYrNwKAWNe92ZJbdY-ck4On_vY",
    authDomain: "chore-298517.firebaseapp.com",
    projectId: "chore-298517",
    storageBucket: "chore-298517.appspot.com",
    messagingSenderId: "756380136767",
    appId: "1:756380136767:web:9b9c0671a8471ac2894f8a",
    measurementId: "G-FFK6DSDGB5"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
