import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  constructor(
    private storage: AngularFireStorage,
    private http: HttpClient
  ) { }

  async save(url: string, data: any) {
    try {
      let blob = new Blob([JSON.stringify(data)], { type: "application/json" })
      return await this.storage.upload('chore-saves/' + url + '.json', blob)
    } catch(e) {
      return false
    }
  }

  async get(url: string) {
    try {
      let resp = await this.storage.ref('chore-saves/' + url + '.json').getDownloadURL().toPromise()
      return await this.http.get(resp, { withCredentials: false }).toPromise()
    } catch(e) {
      return false
    }
  }
}
