import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { StateService } from '../services/state.service';

@Injectable({
  providedIn: 'root'
})
export class NotAuthResolve implements Resolve<any> {
  constructor(private router: Router, private state: StateService) { }

  async resolve() {
    if (this.state.authenticated || localStorage.getItem('reference')) this.router.navigate(['app'])
  }
}