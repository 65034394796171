import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Kid } from 'src/app/classes/person.class';
import { GrownupPasswordComponent } from 'src/app/dialogs/grownup-password/grownup-password.component';
import { KidService } from 'src/app/services/kid.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {

  kidName: string
  qrCodeImage: string

  constructor(
    public state: StateService,
    private kidService: KidService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    var qr = new window['QRious']({
      foreground: '#3f51b5',
      level: 'L',
      padding: 12,
      size: 140,
      value: location.origin + '/app?ref=' + this.state.reference
    });
    this.qrCodeImage = qr.toDataURL()
  }

  createKid(form: NgForm) {
    if (!form.valid) return
    if (this.state.kids.find(kid => kid.name === this.kidName)) return this.snackBar.open('Name already exists', null, { duration: 2000 })
    this.kidService.createKid(this.kidName)
    this.router.navigate(['app', 'manage-grownups', this.kidName])
  }

  menuClick(event: Event) {
    event.stopPropagation()
    return false
  }

  async deleteKid(kid: Kid) {
    if (!kid.grownups.length) return this.kidService.deleteKid(kid)
    const dialogRef = this.dialog.open(GrownupPasswordComponent, { data: { kid } })
    let resp = await dialogRef.afterClosed().toPromise()
    if (resp) this.kidService.deleteKid(kid)
  }

}
