import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Grownup, Kid } from 'src/app/classes/person.class';

@Component({
  selector: 'app-grownup-password',
  templateUrl: './grownup-password.component.html',
  styleUrls: ['./grownup-password.component.scss']
})
export class GrownupPasswordComponent {

  kid: Kid
  grownup: Grownup
  password: string

  constructor(
    public dialogRef: MatDialogRef<GrownupPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) {
    this.kid = this.data.kid
    if (this.kid.grownups.length === 1) this.grownup = this.kid.grownups[0]
  }

  confirm(form: NgForm) {
    if (!form.valid) return
    if (this.password !== this.grownup.password) {
      return this.snackBar.open('Wrong password', null, { duration: 2500 })
    }
    this.dialogRef.close(true)
  }

}
