import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Grownup, Kid } from 'src/app/classes/person.class';
import { GrownupService } from 'src/app/services/grownup.service';
import { KidService } from 'src/app/services/kid.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-manage-parents',
  templateUrl: './manage-parents.component.html',
  styleUrls: ['./manage-parents.component.scss']
})
export class ManageParentsComponent {

  kid: Kid
  grownupName: string
  grownupPassword: string

  constructor(
    private route: ActivatedRoute,
    public state: StateService,
    private kidService: KidService,
    private snackBar: MatSnackBar
  ) {
    this.kid = this.route.snapshot.data.kid
  }

  createGrownup(form: NgForm) {
    if (!form.valid) return
    this.kid.grownups.push(new Grownup(this.grownupName, this.grownupPassword))
    this.grownupName = ''
    this.grownupPassword = ''
    form.resetForm()
    this.snackBar.open('Grownup added', 'close', { duration: 2500, verticalPosition: 'top' })
    this.kidService.saveKids()
  }

  deleteGrownup(grownup: Grownup) {
    let index = this.kid.grownups.indexOf(grownup)
    this.kid.grownups.splice(index, 1)
    this.kidService.saveKids()
  }

  menuClick(event: Event) {
    event.stopPropagation()
    return false
  }

}
