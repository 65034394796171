import { Injectable } from '@angular/core';
import { Grownup } from '../classes/person.class';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class GrownupService {

  constructor(
    private state: StateService
  ) { }
}
